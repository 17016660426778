
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	section.section-header-wrapper {
		.header-background {
			width: 37.5%;
		}
	}

	nav.section-header {
		.header-flexbox {
			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: rgba(255, 255, 255, 0.98);
				background: @color1;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;
						justify-content: center;

						li {
							margin: 10px 0 !important;

							a {
								font-family: 'Frank Ruhl Libre', serif;
								font-size: 30px;
								color: #fff;
								font-weight: 900;
								opacity: 0.6;

								&.active {
									color: #fff;
									opacity: 1;
								}
							}

							&:nth-last-child(1), &:nth-last-child(2) {
								display: none;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.section-header-subpage {
		.header-subpage-flexbox {
			margin-top: 40px;
			text-align: center;
			justify-content: center;

			.flexbox-description {
				text-align: center;

				h1 {
					font-size: 20px;
				}

				a {
					font-size: 35px;
				}
			}
		}
	}

	section.atrakcje-section-content {
		padding-top: 20px;

		.advantages-flexbox {
			margin: 0 10%;
			margin-top: 50px;
			align-items: flex-end;

			.flexbox-service {
				width: 30%;
				display: flex;
				flex-direction: column-reverse;

				.service-image {
					margin-top: -40px;
				}

				.service-description {
					margin-top: 0;
					text-align: center;

					h2 {
						display: flex;
						flex-direction: column-reverse;

						&:before {
							margin: 15px auto 0 auto;
						}
					}
				}
			}

			.flexbox-description {
				width: 65%;
				padding-top: 0;
				padding-right: 0;
				transform: none !important;

				p {
					font-size: 15px;
					line-height: 26px;
					color: #666;
				}
			}

			&:nth-child(even) {
				flex-direction: row;

				.flexbox-description {
					padding-left: 0;
				}
			}
		}
	}

	section.opinie-section-content {
		padding-top: 50px;

		.content-flexbox {
			margin: 0;
			margin-top: 50px;
		}
	}
	
	section.domki-section-content {
		.gallery-nav {
			flex-direction: column;
			align-items: center;

			li {
				margin-right: 0;
				margin: 10px 0;
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	section.section-header-wrapper {
		.header-background {
			width: 44.5%;
		}
	}

	section.section-welcome {
		position: relative;

		.welcome-flexbox {
			.flexbox-image {
				width: 46%;
			}

			.flexbox-description {
				width: 50%;

				span {
					font-size: 24px;
				}

				h1 {
					font-size: 40px;
				}

				p {
					width: 100%;
				}
			}
		}
	}

	section.section-about {
		.about-flexbox {
			.flexbox-description {
				h1 {
					font-size: 40px;
				}

				p {
					width: 100%;
				}
			}
		}
	}

	section.section-subpage-heading {
		.heading-border {
			padding-top: 35px;
		}

		.heading-content {
			margin: 0 10%;

			h2 {
				font-size: 40px;
			}
		}
	}

	section.atrakcje-section-content {
		.advantages-flexbox {
			margin: 0;
			margin-top: 50px;
		}
	}

	section.domki-section-welcome {
		padding-bottom: 0;

		.welcome-background {
			width: 100%;
			height: 100px;
		}

		.welcome-content {
			.content-flexbox {
				flex-direction: column-reverse;
				align-items: center;

				.flexbox-description {
					margin-top: 50px;
					text-align: center;
					width: 100%;

					table {
						width: 100%;
						transform: translateX(-40px);

						td {
							&:nth-child(1) {
								ul {
									float: right;
								}
							}
						}
					}
				}

				.flexbox-image {
					width: 100%;
					max-width: 600px;
					height: 270px;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					img {
						position: absolute;
					}
				}
			}
		}
	}

	section.domki-section-content {
		.content-about {
			.about-flexbox {
				.flexbox-service {
					width: calc(100% / 3);
					padding: 20px;
				}
			}
		}
	}

	section.domki-section-sea {
		.sea-flexbox {
			.flexbox-description {
				p {
					width: 100%;
				}
			}
		}
	}

	section.rezerwacje-section-content {
		.content-legend {
			padding-top: 45px;
			flex-direction: column;

			.legend-contact {
				flex-wrap: wrap;
				justify-content: center;
				margin-left: 0;

				.btn {
					margin: 10px;
				}
			}

			.legend-inner {
				margin-top: 20px;
				width: 100%;
				margin-right: 0;
				flex-direction: column;
				align-items: flex-end;

				.legend-service {
					margin: 0;
					margin-top: 10px;
					flex-direction: row-reverse;

					.service-square {
						margin-left: 10px;
						margin-right: 0;
					}
				}
			}
		}
	}

	section.opinie-section-content {
		.content-flexbox {
			.flexbox-description {
				width: 50%;
			}

			.flexbox-service {
				width: 40%;
			}
		}
	}

	section.kontakt-section-content {
		margin-top: 50px;
		padding-bottom: 0;

		.content-background {
			width: 100%;
			height: 150px;
			background: @color1;
		}

		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-image {
				width: 100%;

				.localisation-map {
					width: 100%;
					height: 270px;
				}
			}

			.flexbox-description {
				margin-top: 50px;
				width: 100%;
				text-align: center;

				address {
					width: 100%;

					a {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}

	section.kontakt-section-info {
		margin-top: 50px;
		padding: 50px 0;
		background: #efefef;

		.info-content {
			p {
				font-size: 30px;
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-wrap: wrap;

			.flexbox-service {
				&:nth-child(1) {
					width: 100%;
					text-align: center;
				}

			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	section.section-header-wrapper {
		padding-bottom: 0;

		.header-slogan {
			display: none;
		}

		.header-background {
			width: 64%;
			height: 350px;
		}

		&.subpage {
			.header-background {
				height: 100%;
			}
		}
	}

	section.section-welcome {
		.welcome-flexbox {
			flex-direction: column;

			.flexbox-image {
				width: 100%;
				height: 270px;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				img {
					position: absolute;
				}
			}

			.flexbox-description {
				margin-top: 50px;
				text-align: center;
				width: 100%;
			}
		}
	}

	section.section-info {
		.info-content {
			img {
				width: 30px;
				margin-bottom: 15px;
			}

			p {
				font-size: 30px;
			}
		}

		&.gray-background {
			padding: 50px 0;
			margin: 50px 0;
			background: #efefef;
		}
	}

	section.section-about {
		padding-bottom: 0;

		.about-background {
			width: 100%;
			height: 150px;
			background: @color1;
		}

		.about-slogan {
			display: none;
		}

		.about-flexbox {
			flex-direction: column-reverse;

			.flexbox-image {
				width: 100%;

				.localisation-map {
					width: 100%;
					height: 270px;
				}
			}

			.flexbox-description {
				margin-top: 50px;
				width: 100%;
				text-align: center;
			}
		}
	}

	section.section-advantages {
		padding-bottom: 70px;

		.advantages-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				.service-image {
					width: 255px;
					padding-top: 255px;
				}

				&:nth-child(2) {
					margin-top: 50px;
				}
			}
		}
	}

	section.atrakcje-section-content {
		.advantages-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				width: 255px;
				flex-direction: column;

				.service-image {
					margin-top: 0;
				}

				.service-description {
					margin-top: -40px;
					text-align: center;

					h2 {
						flex-direction: column;

						&:before {
							margin: 0 auto 15px auto;
						}
					}
				}
			}

			.flexbox-description {
				width: 100%;
				text-align: center;
				padding-top: 30px;
			}

			&:nth-child(even) {
				flex-direction: column;

				.flexbox-service {
					flex-direction: column;

					.service-image {
						margin-top: 0;
					}

					.service-description {
						margin-top: -40px;
						flex-direction: column;

						h2 {
							flex-direction: column;

							&:before {
								margin: 0 auto 15px auto;
							}
						}
					}
				}

				.flexbox-description {
					padding-left: 0;
				}
			}
		}
	}

	section.domki-section-content {
		.content-about {
			.about-flexbox {
				.flexbox-service {
					width: calc(100% / 2);
				}
			}
		}

		.gallery-flexbox {
			.flexbox-service-wrapper {
				width: 47%;

				&:nth-child(n+3) {
					margin-top: 30px;
				}
			}

			&.row-3 {
				.flexbox-service-wrapper {
					&:nth-child(3) {
						display: none;
					}
				}
			}
		}
	}

	section.domki-section-sea {
		padding-bottom: 0;

		.sea-background {
			width: 100%;
			height: 150px;
			background: @color1;
		}

		.sea-slogan {
			display: none;
		}

		.sea-flexbox {
			flex-direction: column;

			.flexbox-image {
				width: 100%;

				.localisation-map {
					width: 100%;
					height: 270px;
				}
			}

			.flexbox-description {
				margin-top: 50px;
				width: 100%;
				text-align: center;

				h2 {
					br {
						content: '';

						&:after {
							content: ' ';
						}
					}
				}
			}
		}
	}

	section.rezerwacje-section-content {
		.content-calendar {
			.calendar-houses {
				width: 20%;
				min-width: 81px;
				padding-top: 100px;

				.house-service {
					padding: 0 0 0 10px;
					height: 40px;

					img {
						display: block;
						width: 18px;
					}

					p {
						font-size: 16px;
						margin-left: 8px;

						span {
							display: none;
						}
					}
				}
			}

			.calendar-dates {
				.dates-flexbox {
					.date-column {
						min-width: 40px;

						.column-cell {
							height: 40px;
							padding: 6px;

							.free {
								width: 100%;
								height: 100%;
								background: #4e950f;
								border-radius: 3px;
							}

							.reserved {
								height: 100%;
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								font-weight: 900;
								font-family: 'Frank Ruhl Libre', serif;
								text-transform: uppercase;
								color: #aaa;
								background: #ddd;
								border-radius: 3px;

								&:after {
									content: 'r';
								}
							}

							&:not(:nth-child(2)) {
								border-top: none;
							}
						}

						&.saturday {
							background: #f9f9f9;
						}

						&.sunday {
							background: #f3f3f3;
						}

						&:last-child {
							.column-heading, .column-cell {
								border-right: none;
							}
						}
					}
				}
			}
		}
	}

	section.opinie-section-content {
		padding-top: 0;

		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-description {
				margin-top: 30px;
				width: 100%;
				text-align: center;
			}

			.flexbox-service {
				width: 100%;
				text-align: center;
				background: @color1;
				padding: 30px;

				h3 {
					font-family: 'Frank Ruhl Libre', serif;
					font-size: 25px;
					color: #fff;
					font-weight: 900;
				}

				p {
					margin-top: 5px;
					font-weight: 300;
					font-size: 13px;
					line-height: 26px;
					padding-left: 4px;
					color: #fff;
				}
			}

			&:nth-child(even) {
				flex-direction: column-reverse;
			}
		}
	}

	section.section-footer {
		.footer-credits {
			text-align: center;
			justify-content: center;
		}

		.alert {
			text-align: center;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	section.section-subpage-heading {
		.heading-content {
			margin: 0;
		}
	}

	nav.section-header {
		.header-flexbox {
			padding: 40px 0;

			.flexbox-logo {
				img {
					height: 40px;
				}
			}

			.flexbox-nav-wrapper {
				.nav-button-close {
					button {
						margin-right: 0;
					}
				}
			}
		}
	}

	section.rezerwacje-section-content {
		.content-calendar {
			.calendar-houses {
				width: 20%;
				min-width: 81px;
				padding-top: 100px;

				.house-service {
					padding: 0 0 0 10px;
					height: 40px;

					img {
						display: block;
						width: 18px;
					}

					p {
						font-size: 16px;
						margin-left: 8px;

						span {
							display: none;
						}
					}
				}
			}

			.calendar-dates {
				.dates-flexbox {
					.date-column {
						min-width: 40px;

						.column-cell {
							height: 40px;
							padding: 6px;

							.free {
								width: 100%;
								height: 100%;
								background: #4e950f;
								border-radius: 3px;
							}

							.reserved {
								height: 100%;
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								font-weight: 900;
								font-family: 'Frank Ruhl Libre', serif;
								text-transform: uppercase;
								color: #aaa;
								background: #ddd;
								border-radius: 3px;

								&:after {
									content: 'r';
								}
							}

							&:not(:nth-child(2)) {
								border-top: none;
							}
						}

						&.saturday {
							background: #f9f9f9;
						}

						&.sunday {
							background: #f3f3f3;
						}

						&:last-child {
							.column-heading, .column-cell {
								border-right: none;
							}
						}
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-flexbox {
			.flexbox-description {
				address {
					a {
						font-size: 20px;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		.content-pricing {
			.pricing-heading {
				font-size: 20px;

				small {
					white-space: nowrap;
				}
			}

			.pricing-list {
				font-size: 11px;

				.list-date {
					width: auto;
					padding: 10px 20px;
				}

				.list-price {
					padding: 10px 20px;
				}
			}

			&:not(:first-child) {
				margin-top: 50px;
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 50px;

				.list-social {
					justify-content: center;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
	section.domki-section-welcome {
		.welcome-content {
			.content-flexbox {
				.flexbox-description {
					table {
						transform: translateX(0);
					}
				}
			}
		}
	}
}