@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v6/j8_w6_fAw7jrcalD7oKYNX0QfAnPW77l5A.ttf) format('truetype');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPU0PG9JnGgQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUwvA9JnGgQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUzPC9JnGgQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwaPHA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body .btn {
  padding: 15px 35px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  white-space: normal;
}
body .btn img {
  margin-left: 15px;
  height: 10px;
}
body .btn.btn-default {
  background: #333;
  color: #fff;
  border: 1px solid #333;
}
body .btn.btn-default:hover {
  background: #1a1a1a;
  border-color: #1a1a1a;
}
body .btn.btn-blue {
  background: #14215e;
  color: #fff;
  border: 1px solid #14215e;
}
body .btn.btn-blue:hover {
  background: #0b1234;
  border-color: #0b1234;
}
body .btn.btn-small {
  padding: 8px 20px 7px 20px;
  font-size: 11px;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
section.section-header-wrapper {
  position: relative;
  padding-bottom: 100px;
}
section.section-header-wrapper.subpage {
  padding-bottom: 0;
}
section.section-header-wrapper .header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 37%;
  height: 100%;
  background: #14215e;
}
section.section-header-wrapper .header-slogan {
  position: absolute;
  bottom: 0;
  left: 50px;
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  transform-origin: bottom left;
}
section.section-header-wrapper .header-slogan span {
  display: block;
  width: 300px;
  height: 1px;
  background: #fff;
  margin-right: 20px;
}
section.section-header-wrapper .header-slogan p {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}
nav.section-header {
  position: relative;
}
nav.section-header .header-flexbox {
  position: relative;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 50px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 60px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  margin-right: 40px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #000;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a img {
  height: 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  color: #14215e;
  font-weight: 900;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-child(7) {
  margin-right: 10px;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
  cursor: pointer;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #14215e;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .header-flexbox .nav-button.hidden .button-bar {
  opacity: 0;
  visibility: hidden;
}
.section-header-subpage {
  position: relative;
}
.section-header-subpage .header-subpage-flexbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.section-header-subpage .header-subpage-flexbox .flexbox-description {
  text-align: right;
}
.section-header-subpage .header-subpage-flexbox .flexbox-description h1 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 25px;
  color: #333;
  font-weight: 900;
}
.section-header-subpage .header-subpage-flexbox .flexbox-description a {
  margin-top: 25px;
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  font-weight: 900;
  color: #14215e;
}
section.section-welcome {
  position: relative;
}
section.section-welcome .welcome-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-welcome .welcome-flexbox .flexbox-image {
  width: 50%;
}
section.section-welcome .welcome-flexbox .flexbox-image img {
  width: 100%;
}
section.section-welcome .welcome-flexbox .flexbox-description {
  width: 43%;
}
section.section-welcome .welcome-flexbox .flexbox-description span {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 30px;
  color: #14215e;
  font-weight: 900;
}
section.section-welcome .welcome-flexbox .flexbox-description h1 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 55px;
  color: #333;
  font-weight: 900;
}
section.section-welcome .welcome-flexbox .flexbox-description p {
  margin-top: 25px;
  font-size: 15px;
  line-height: 26px;
  padding-left: 4px;
  width: 80%;
  color: #666;
}
section.section-welcome .welcome-flexbox .flexbox-description p a {
  font-weight: 900;
  color: #14215e;
  text-decoration: underline !important;
}
section.section-welcome .welcome-flexbox .flexbox-description p strong {
  font-weight: 900;
  color: #14215e;
}
section.section-welcome .welcome-flexbox .flexbox-description p:first-of-type {
  margin-top: 0;
  width: 100%;
  padding-left: 0;
  font-family: "Frank Ruhl Libre", serif;
  font-size: 30px;
  color: #14215e;
  font-weight: 900;
}
section.section-welcome .welcome-flexbox .flexbox-description .btn {
  margin-top: 25px;
}
section.section-info {
  padding: 70px 0;
}
section.section-info .info-content {
  text-align: center;
}
section.section-info .info-content img {
  width: 50px;
  margin-bottom: 20px;
}
section.section-info .info-content p {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  color: #333;
  font-weight: 900;
}
section.section-info .info-content p a {
  color: #14215e;
  white-space: nowrap;
}
section.section-about {
  padding: 100px 0;
  position: relative;
}
section.section-about .about-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background: #14215e;
}
section.section-about .about-slogan {
  position: absolute;
  bottom: 0;
  right: 50px;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  transform-origin: bottom right;
}
section.section-about .about-slogan span {
  display: block;
  width: 250px;
  height: 1px;
  background: #fff;
  margin-left: 20px;
}
section.section-about .about-slogan p {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}
section.section-about .about-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-about .about-flexbox .flexbox-image {
  width: 50%;
}
section.section-about .about-flexbox .flexbox-image .localisation-map {
  width: 100%;
  height: 400px;
}
section.section-about .about-flexbox .flexbox-description {
  width: 43%;
}
section.section-about .about-flexbox .flexbox-description h1 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 55px;
  color: #333;
  font-weight: 900;
}
section.section-about .about-flexbox .flexbox-description p {
  margin-top: 25px;
  font-size: 15px;
  line-height: 26px;
  padding-left: 4px;
  width: 80%;
  color: #666;
}
section.section-about .about-flexbox .flexbox-description p a {
  font-weight: 900;
  color: #14215e;
  text-decoration: underline !important;
}
section.section-about .about-flexbox .flexbox-description .btn {
  margin-top: 25px;
}
section.section-advantages {
  padding-bottom: 100px;
}
section.section-advantages .advantages-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-advantages .advantages-flexbox .flexbox-service {
  width: 30%;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-image {
  width: 100%;
  padding-top: 100%;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-description {
  margin-top: -40px;
  text-align: center;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-description h2 {
  font-size: 17px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-description h2:before {
  content: "";
  display: block;
  margin: 0 auto 15px auto;
  height: 80px;
  width: 3px;
  background: #333;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-description p {
  margin-top: 10px;
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-description .btn {
  margin-top: 20px;
}
section.section-advantages .advantages-flexbox .flexbox-service:nth-child(2) {
  margin-top: 100px;
}
section.section-advantages .advantages-flexbox .flexbox-service:nth-child(3) {
  margin-top: 50px;
}
section.section-subpage-heading .heading-border {
  margin-top: 35px;
  padding-top: 50px;
  height: 1px;
  width: 100%;
  border-top: 1px solid #efefef;
}
section.section-subpage-heading .heading-content {
  margin: 0 20%;
  text-align: center;
}
section.section-subpage-heading .heading-content h1,
section.section-subpage-heading .heading-content h2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 55px;
  color: #333;
  font-weight: 900;
}
section.section-subpage-heading .heading-content h1 a,
section.section-subpage-heading .heading-content h2 a {
  color: #14215e;
}
section.section-subpage-heading .heading-content p {
  margin-top: 15px;
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
section.section-subpage-heading .heading-content p a {
  font-weight: 900;
  color: #14215e;
  text-decoration: underline !important;
}
section.atrakcje-section-content {
  padding-top: 70px;
  padding-bottom: 10px;
}
section.atrakcje-section-content .advantages-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-service {
  width: 30%;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-image {
  width: 100%;
  padding-top: 100%;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description {
  margin-top: -40px;
  text-align: center;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2 {
  font-size: 17px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2:before {
  content: "";
  display: block;
  margin: 0 auto 15px auto;
  height: 80px;
  width: 3px;
  background: #333;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-description {
  width: 65%;
  padding-right: 20%;
  transform: translateY(-35px);
}
section.atrakcje-section-content .advantages-flexbox .flexbox-description p {
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
section.atrakcje-section-content .advantages-flexbox .flexbox-description p strong {
  font-weight: 900;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) {
  flex-direction: row-reverse;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service {
  display: flex;
  flex-direction: column-reverse;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-image {
  margin-top: -40px;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description {
  margin-top: 0;
  text-align: center;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description h2 {
  display: flex;
  flex-direction: column-reverse;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description h2:before {
  margin: 15px auto 0 auto;
}
section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-description {
  padding-right: 0;
  padding-left: 20%;
  transform: translateY(35px);
}
section.domki-section-welcome {
  position: relative;
  margin-top: 60px;
  padding: 50px 0;
}
section.domki-section-welcome .welcome-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background: #14215e;
}
section.domki-section-welcome .welcome-content {
  position: relative;
}
section.domki-section-welcome .welcome-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description {
  width: 47%;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description h2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  color: #333;
  font-weight: 900;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description p {
  margin-top: 25px;
  font-size: 15px;
  line-height: 26px;
  padding-left: 4px;
  width: 90%;
  color: #666;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description p a {
  font-weight: 900;
  color: #14215e;
  text-decoration: underline !important;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description p strong {
  font-weight: 900;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description ul {
  padding-left: 5px;
  margin-top: 20px;
  list-style: none;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description ul li {
  font-size: 15px;
  line-height: 26px;
  color: #666;
  display: flex;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description ul li:before {
  content: "\2022";
  color: #14215e;
  margin-right: 5px;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description ul li:not(:last-child) {
  margin-bottom: 5px;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description .btn {
  margin-top: 25px;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-image {
  width: 50%;
}
section.domki-section-welcome .welcome-content .content-flexbox .flexbox-image img {
  width: 100%;
}
section.domki-section-content {
  padding-bottom: 70px;
}
section.domki-section-content .content-about {
  background: #efefef;
  padding: 50px 0;
  margin-bottom: 70px;
}
section.domki-section-content .content-about .about-flexbox {
  display: flex;
  flex-wrap: wrap;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service {
  width: calc(100% / 5);
  padding: 20px;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service h2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 20px;
  color: #333;
  font-weight: 900;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service ul {
  margin-top: 10px;
  list-style: none;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service ul li {
  font-size: 13px;
  line-height: 26px;
  color: #666;
  display: flex;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service ul li:before {
  content: "\2022";
  color: #14215e;
  margin-right: 5px;
}
section.domki-section-content .content-about .about-flexbox .flexbox-service ul li:not(:last-child) {
  margin-bottom: 5px;
}
section.domki-section-content .gallery-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
section.domki-section-content .gallery-nav li {
  margin-right: 5px;
}
section.domki-section-content .gallery-nav li a,
section.domki-section-content .gallery-nav li button {
  background: none;
  padding: 15px;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  white-space: normal;
  cursor: pointer;
  color: #333;
}
section.domki-section-content .gallery-nav li a:hover,
section.domki-section-content .gallery-nav li button:hover {
  background: #14215e;
  color: #fff;
}
section.domki-section-content .gallery-nav li.active a,
section.domki-section-content .gallery-nav li.active button {
  background: #14215e;
  color: #fff;
}
section.domki-section-content .gallery-flexbox {
  margin-top: -3.5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper {
  margin-top: 3.5%;
  width: 31%;
  position: relative;
  overflow: hidden;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service {
  width: 100%;
  padding-top: 53%;
  display: block;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service .service-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service .service-inner img {
  width: 100%;
  transition: all 0.3s;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service iframe,
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper .flexbox-service:hover .service-inner img {
  transform: scale(1.06);
}
section.domki-section-content .gallery-flexbox .flexbox-service-wrapper.service-hidden {
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  border: none;
}
section.domki-section-content.galeria {
  padding-top: 40px;
  padding-bottom: 0;
}
section.domki-section-sea {
  padding: 100px 0;
  margin-bottom: 10px;
  position: relative;
}
section.domki-section-sea .sea-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  height: 100%;
  background: #14215e;
}
section.domki-section-sea .sea-slogan {
  position: absolute;
  bottom: 0;
  left: 50px;
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  transform-origin: bottom left;
}
section.domki-section-sea .sea-slogan span {
  display: block;
  width: 300px;
  height: 1px;
  background: #fff;
  margin-right: 20px;
}
section.domki-section-sea .sea-slogan p {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}
section.domki-section-sea .sea-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.domki-section-sea .sea-flexbox .flexbox-image {
  width: 50%;
}
section.domki-section-sea .sea-flexbox .flexbox-image .localisation-map {
  width: 100%;
  height: 400px;
}
section.domki-section-sea .sea-flexbox .flexbox-description {
  width: 43%;
}
section.domki-section-sea .sea-flexbox .flexbox-description h2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  color: #333;
  font-weight: 900;
}
section.domki-section-sea .sea-flexbox .flexbox-description p {
  margin-top: 25px;
  font-size: 15px;
  line-height: 26px;
  padding-left: 4px;
  width: 80%;
  color: #666;
}
section.domki-section-sea .sea-flexbox .flexbox-description p a {
  font-weight: 900;
  color: #14215e;
  text-decoration: underline !important;
}
section.domki-section-sea .sea-flexbox .flexbox-description p strong {
  font-weight: 900;
}
section.domki-section-sea .sea-flexbox .flexbox-description .btn {
  margin-top: 25px;
}
section.rezerwacje-section-content .content-legend {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}
section.rezerwacje-section-content .content-legend .legend-contact {
  display: flex;
  align-items: center;
  margin-left: -10px;
}
section.rezerwacje-section-content .content-legend .legend-contact .btn {
  margin: 10px;
}
section.rezerwacje-section-content .content-legend .legend-inner {
  margin-right: -10px;
  display: flex;
  align-items: center;
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service {
  display: flex;
  align-items: center;
  margin: 10px;
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square {
  height: 28px;
  min-width: 28px;
  max-width: 28px;
  border-radius: 3px;
  margin-right: 10px;
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square.free {
  background: #4e950f;
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square.reserved {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-family: "Frank Ruhl Libre", serif;
  text-transform: uppercase;
  color: #aaa;
  background: #ddd;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square.reserved .reserved-half-from {
  position: absolute;
  background: #4e950f;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square.reserved .reserved-half-to {
  position: absolute;
  background: #4e950f;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 0 0);
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square.reserved:after {
  content: "r";
}
section.rezerwacje-section-content .content-legend .legend-inner .legend-service p {
  font-size: 12px;
  color: #14215e;
}
section.rezerwacje-section-content .content-calendar-navigation {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
section.rezerwacje-section-content .content-calendar-navigation button {
  border: none;
  background: #14215e;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 5px 15px;
}
section.rezerwacje-section-content .content-calendar-navigation button img {
  height: 20px;
}
section.rezerwacje-section-content .content-calendar-navigation button:hover {
  background: #0b1234;
}
section.rezerwacje-section-content .content-calendar-navigation button:last-child {
  margin-left: 5px;
}
section.rezerwacje-section-content .content-calendar-navigation button:last-child img {
  transform: rotate(180deg);
}
section.rezerwacje-section-content .content-calendar {
  display: flex;
}
section.rezerwacje-section-content .content-calendar .calendar-houses {
  width: 20%;
  min-width: 160px;
  padding-top: 100px;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  background: none;
  border: 0.5px solid #efefef;
  border-right: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service img {
  display: none;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service p {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 20px;
  color: #333;
  font-weight: 900;
  transition: all 0.3s;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service:hover {
  background: #d9dbe7;
  background: #14215e;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service:hover p {
  color: #fff;
}
section.rezerwacje-section-content .content-calendar .calendar-houses .house-service:not(:first-child) {
  border-top: none;
}
section.rezerwacje-section-content .content-calendar .calendar-dates {
  width: 80%;
  border-left: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox {
  display: flex;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column {
  min-width: 60px;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading {
  height: 100px;
  border: 0.5px solid #ddd;
  border-left: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading .year {
  font-size: 10px;
  font-weight: 400;
  color: #888;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading .name {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  color: #333;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading .name {
  font-size: 12px;
  font-weight: 300;
  color: #333;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading .day {
  margin-top: 5px;
  font-weight: 900;
  font-family: "Frank Ruhl Libre", serif;
  color: #14215e;
  line-height: 22px;
  font-size: 22px;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-heading .month {
  font-size: 12px;
  font-weight: 600;
  color: #14215e;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell {
  border: 0.5px solid #ddd;
  border-left: none;
  height: 60px;
  padding: 13px;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .free {
  width: 100%;
  height: 100%;
  background: #4e950f;
  border-radius: 3px;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-family: "Frank Ruhl Libre", serif;
  text-transform: uppercase;
  color: #aaa;
  background: #ddd;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved .reserved-half-from {
  position: absolute;
  background: #4e950f;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved .reserved-half-to {
  position: absolute;
  background: #4e950f;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 0 0);
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved:after {
  content: "r";
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell:not(:nth-child(2)) {
  border-top: none;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.saturday {
  background: #f9f9f9;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.sunday {
  background: #f3f3f3;
}
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-heading,
section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-cell {
  border-right: none;
}
.modal-content {
  border: none;
  border-radius: 0;
}
.modal-content .modal-header {
  border-bottom: none;
}
.modal-content .modal-title {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 28px;
  color: #333;
  font-weight: 900;
}
.modal-content .modal-photo img {
  width: 100%;
}
.modal-content .modal-pricing {
  padding: 1rem;
}
.modal-content .modal-pricing .heading {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 22px;
  color: #333;
  font-weight: 900;
  margin-bottom: 10px;
}
.modal-content .modal-pricing .pricing-row {
  display: flex;
  justify-content: space-between;
}
.modal-content .modal-pricing .pricing-row p {
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
.modal-content .modal-pricing .pricing-row p b {
  font-weight: 900;
}
.modal-content .modal-pricing .pricing-row:not(:last-child) {
  border-bottom: 0.5px solid #efefef;
}
.modal-content .close {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  transform: translate(-18px, 22px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.modal-content .close .button-bar {
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  background: #333;
}
.modal-content .close .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-content .close .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.opinie-section-content {
  padding-top: 50px;
}
section.opinie-section-content .content-flexbox {
  border-top: 1px solid #efefef;
  padding-top: 50px;
  margin: 0 10%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.opinie-section-content .content-flexbox .flexbox-description {
  width: 65%;
}
section.opinie-section-content .content-flexbox .flexbox-description p {
  font-size: 17px;
  line-height: 26px;
  color: #666;
  font-style: italic;
}
section.opinie-section-content .content-flexbox .flexbox-description p:before,
section.opinie-section-content .content-flexbox .flexbox-description p:after {
  content: '"';
}
section.opinie-section-content .content-flexbox .flexbox-service {
  width: 30%;
  background: #14215e;
  padding: 30px;
}
section.opinie-section-content .content-flexbox .flexbox-service h3 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 25px;
  color: #fff;
  font-weight: 900;
}
section.opinie-section-content .content-flexbox .flexbox-service p {
  margin-top: 5px;
  font-weight: 300;
  font-size: 13px;
  line-height: 26px;
  padding-left: 4px;
  color: #fff;
}
section.opinie-section-content .content-flexbox:nth-child(1) {
  padding-top: 0;
  border: none;
}
section.opinie-section-content .content-flexbox:nth-child(even) {
  flex-direction: row-reverse;
}
section.kontakt-section-content {
  margin-top: 70px;
  padding: 50px 0;
  position: relative;
}
section.kontakt-section-content .content-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background: #14215e;
}
section.kontakt-section-content .content-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.kontakt-section-content .content-flexbox .flexbox-image {
  width: 50%;
}
section.kontakt-section-content .content-flexbox .flexbox-image .localisation-map {
  width: 100%;
  height: 400px;
}
section.kontakt-section-content .content-flexbox .flexbox-description {
  width: 43%;
}
section.kontakt-section-content .content-flexbox .flexbox-description h2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  color: #333;
  font-weight: 900;
}
section.kontakt-section-content .content-flexbox .flexbox-description address {
  margin-top: 25px;
  font-size: 28px;
  line-height: 26px;
  padding-left: 4px;
  width: 80%;
  color: #666;
}
section.kontakt-section-content .content-flexbox .flexbox-description address a {
  font-family: "Frank Ruhl Libre", serif;
  font-weight: 900;
  color: #14215e;
}
section.kontakt-section-content .content-flexbox .flexbox-description address a:nth-child(2) {
  display: inline-block;
  margin-top: 10px;
}
section.kontakt-section-content .content-flexbox .flexbox-description .btn {
  margin-top: 25px;
}
section.kontakt-section-info {
  padding: 70px 0 60px 0;
}
section.kontakt-section-info .info-content {
  text-align: center;
}
section.kontakt-section-info .info-content p {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 40px;
  color: #333;
  font-weight: 900;
}
section.kontakt-section-info .info-content p a {
  color: #14215e;
}
section.kontakt-section-info .info-content img {
  height: 30px;
}
section.static-page-section-content {
  padding: 50px 0;
}
section.static-page-section-content .content-text > * {
  margin-top: 20px;
}
section.static-page-section-content .content-text > *:first-child {
  margin-top: 0;
}
section.static-page-section-content .content-text p {
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
section.static-page-section-content .content-text ul,
section.static-page-section-content .content-text ol {
  padding-left: 18px;
  margin-bottom: 0;
}
section.static-page-section-content .content-text ul li,
section.static-page-section-content .content-text ol li {
  font-size: 15px;
  line-height: 26px;
  color: #666;
}
section.static-page-section-content .content-text ul ul,
section.static-page-section-content .content-text ol ul,
section.static-page-section-content .content-text ul ol,
section.static-page-section-content .content-text ol ol {
  margin-top: 0;
  list-style-type: lower-alpha;
}
section.static-page-section-content .content-text ul ul li,
section.static-page-section-content .content-text ol ul li,
section.static-page-section-content .content-text ul ol li,
section.static-page-section-content .content-text ol ol li {
  opacity: 1;
}
section.static-page-section-content .content-pricing .pricing-heading {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
section.static-page-section-content .content-pricing .pricing-list {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
section.static-page-section-content .content-pricing .pricing-list .list-date {
  width: 300px;
  text-align: center;
  padding: 10px;
  background: #f9f9f9;
}
section.static-page-section-content .content-pricing .pricing-list .list-price {
  background: #14215e;
  color: #fff;
  padding: 10px 30px;
  font-weight: 300;
}
section.static-page-section-content .content-pricing .pricing-list .list-price strong {
  font-weight: 800;
}
section.static-page-section-content .content-pricing:not(:first-child) {
  margin-top: 50px;
}
section.section-footer {
  padding: 20px 0 50px 0;
  background: #0b1234;
  position: relative;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 50px;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo {
  height: 50px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 16px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 12px;
  color: #e6e6e5;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #e6e6e5;
}
section.section-footer .alert {
  position: relative;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 50px 0 0 0;
  background: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
section.section-footer .alert p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #e6e6e5;
  cursor: pointer;
  font-weight: 700;
}
@media (max-width: 1199.98px) {
  section.section-header-wrapper .header-background {
    width: 37.5%;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.98);
    background: #14215e;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    justify-content: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin: 10px 0 !important;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-family: 'Frank Ruhl Libre', serif;
    font-size: 30px;
    color: #fff;
    font-weight: 900;
    opacity: 0.6;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
    color: #fff;
    opacity: 1;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-last-child(1),
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-last-child(2) {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .section-header-subpage .header-subpage-flexbox {
    margin-top: 40px;
    text-align: center;
    justify-content: center;
  }
  .section-header-subpage .header-subpage-flexbox .flexbox-description {
    text-align: center;
  }
  .section-header-subpage .header-subpage-flexbox .flexbox-description h1 {
    font-size: 20px;
  }
  .section-header-subpage .header-subpage-flexbox .flexbox-description a {
    font-size: 35px;
  }
  section.atrakcje-section-content {
    padding-top: 20px;
  }
  section.atrakcje-section-content .advantages-flexbox {
    margin: 0 10%;
    margin-top: 50px;
    align-items: flex-end;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service {
    width: 30%;
    display: flex;
    flex-direction: column-reverse;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-image {
    margin-top: -40px;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description {
    margin-top: 0;
    text-align: center;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2 {
    display: flex;
    flex-direction: column-reverse;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2:before {
    margin: 15px auto 0 auto;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-description {
    width: 65%;
    padding-top: 0;
    padding-right: 0;
    transform: none !important;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-description p {
    font-size: 15px;
    line-height: 26px;
    color: #666;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) {
    flex-direction: row;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-description {
    padding-left: 0;
  }
  section.opinie-section-content {
    padding-top: 50px;
  }
  section.opinie-section-content .content-flexbox {
    margin: 0;
    margin-top: 50px;
  }
  section.domki-section-content .gallery-nav {
    flex-direction: column;
    align-items: center;
  }
  section.domki-section-content .gallery-nav li {
    margin-right: 0;
    margin: 10px 0;
  }
}
@media (max-width: 991.98px) {
  section.section-header-wrapper .header-background {
    width: 44.5%;
  }
  section.section-welcome {
    position: relative;
  }
  section.section-welcome .welcome-flexbox .flexbox-image {
    width: 46%;
  }
  section.section-welcome .welcome-flexbox .flexbox-description {
    width: 50%;
  }
  section.section-welcome .welcome-flexbox .flexbox-description span {
    font-size: 24px;
  }
  section.section-welcome .welcome-flexbox .flexbox-description h1 {
    font-size: 40px;
  }
  section.section-welcome .welcome-flexbox .flexbox-description p {
    width: 100%;
  }
  section.section-about .about-flexbox .flexbox-description h1 {
    font-size: 40px;
  }
  section.section-about .about-flexbox .flexbox-description p {
    width: 100%;
  }
  section.section-subpage-heading .heading-border {
    padding-top: 35px;
  }
  section.section-subpage-heading .heading-content {
    margin: 0 10%;
  }
  section.section-subpage-heading .heading-content h2 {
    font-size: 40px;
  }
  section.atrakcje-section-content .advantages-flexbox {
    margin: 0;
    margin-top: 50px;
  }
  section.domki-section-welcome {
    padding-bottom: 0;
  }
  section.domki-section-welcome .welcome-background {
    width: 100%;
    height: 100px;
  }
  section.domki-section-welcome .welcome-content .content-flexbox {
    flex-direction: column-reverse;
    align-items: center;
  }
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description table {
    width: 100%;
    transform: translateX(-40px);
  }
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description table td:nth-child(1) ul {
    float: right;
  }
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-image {
    width: 100%;
    max-width: 600px;
    height: 270px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-image img {
    position: absolute;
  }
  section.domki-section-content .content-about .about-flexbox .flexbox-service {
    width: calc(100% / 3);
    padding: 20px;
  }
  section.domki-section-sea .sea-flexbox .flexbox-description p {
    width: 100%;
  }
  section.rezerwacje-section-content .content-legend {
    padding-top: 45px;
    flex-direction: column;
  }
  section.rezerwacje-section-content .content-legend .legend-contact {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
  }
  section.rezerwacje-section-content .content-legend .legend-contact .btn {
    margin: 10px;
  }
  section.rezerwacje-section-content .content-legend .legend-inner {
    margin-top: 20px;
    width: 100%;
    margin-right: 0;
    flex-direction: column;
    align-items: flex-end;
  }
  section.rezerwacje-section-content .content-legend .legend-inner .legend-service {
    margin: 0;
    margin-top: 10px;
    flex-direction: row-reverse;
  }
  section.rezerwacje-section-content .content-legend .legend-inner .legend-service .service-square {
    margin-left: 10px;
    margin-right: 0;
  }
  section.opinie-section-content .content-flexbox .flexbox-description {
    width: 50%;
  }
  section.opinie-section-content .content-flexbox .flexbox-service {
    width: 40%;
  }
  section.kontakt-section-content {
    margin-top: 50px;
    padding-bottom: 0;
  }
  section.kontakt-section-content .content-background {
    width: 100%;
    height: 150px;
    background: #14215e;
  }
  section.kontakt-section-content .content-flexbox {
    flex-direction: column-reverse;
  }
  section.kontakt-section-content .content-flexbox .flexbox-image {
    width: 100%;
  }
  section.kontakt-section-content .content-flexbox .flexbox-image .localisation-map {
    width: 100%;
    height: 270px;
  }
  section.kontakt-section-content .content-flexbox .flexbox-description {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-description address {
    width: 100%;
  }
  section.kontakt-section-content .content-flexbox .flexbox-description address a {
    display: flex;
    justify-content: center;
  }
  section.kontakt-section-info {
    margin-top: 50px;
    padding: 50px 0;
    background: #efefef;
  }
  section.kontakt-section-info .info-content p {
    font-size: 30px;
  }
  section.section-footer .footer-flexbox {
    flex-wrap: wrap;
  }
  section.section-footer .footer-flexbox .flexbox-service:nth-child(1) {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  section.section-header-wrapper {
    padding-bottom: 0;
  }
  section.section-header-wrapper .header-slogan {
    display: none;
  }
  section.section-header-wrapper .header-background {
    width: 64%;
    height: 350px;
  }
  section.section-header-wrapper.subpage .header-background {
    height: 100%;
  }
  section.section-welcome .welcome-flexbox {
    flex-direction: column;
  }
  section.section-welcome .welcome-flexbox .flexbox-image {
    width: 100%;
    height: 270px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  section.section-welcome .welcome-flexbox .flexbox-image img {
    position: absolute;
  }
  section.section-welcome .welcome-flexbox .flexbox-description {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }
  section.section-info .info-content img {
    width: 30px;
    margin-bottom: 15px;
  }
  section.section-info .info-content p {
    font-size: 30px;
  }
  section.section-info.gray-background {
    padding: 50px 0;
    margin: 50px 0;
    background: #efefef;
  }
  section.section-about {
    padding-bottom: 0;
  }
  section.section-about .about-background {
    width: 100%;
    height: 150px;
    background: #14215e;
  }
  section.section-about .about-slogan {
    display: none;
  }
  section.section-about .about-flexbox {
    flex-direction: column-reverse;
  }
  section.section-about .about-flexbox .flexbox-image {
    width: 100%;
  }
  section.section-about .about-flexbox .flexbox-image .localisation-map {
    width: 100%;
    height: 270px;
  }
  section.section-about .about-flexbox .flexbox-description {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
  section.section-advantages {
    padding-bottom: 70px;
  }
  section.section-advantages .advantages-flexbox {
    flex-direction: column;
  }
  section.section-advantages .advantages-flexbox .flexbox-service {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.section-advantages .advantages-flexbox .flexbox-service .service-image {
    width: 255px;
    padding-top: 255px;
  }
  section.section-advantages .advantages-flexbox .flexbox-service:nth-child(2) {
    margin-top: 50px;
  }
  section.atrakcje-section-content .advantages-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service {
    width: 255px;
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-image {
    margin-top: 0;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description {
    margin-top: -40px;
    text-align: center;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2 {
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-service .service-description h2:before {
    margin: 0 auto 15px auto;
  }
  section.atrakcje-section-content .advantages-flexbox .flexbox-description {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) {
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service {
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-image {
    margin-top: 0;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description {
    margin-top: -40px;
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description h2 {
    flex-direction: column;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-service .service-description h2:before {
    margin: 0 auto 15px auto;
  }
  section.atrakcje-section-content .advantages-flexbox:nth-child(even) .flexbox-description {
    padding-left: 0;
  }
  section.domki-section-content .content-about .about-flexbox .flexbox-service {
    width: calc(100% / 2);
  }
  section.domki-section-content .gallery-flexbox .flexbox-service-wrapper {
    width: 47%;
  }
  section.domki-section-content .gallery-flexbox .flexbox-service-wrapper:nth-child(n+3) {
    margin-top: 30px;
  }
  section.domki-section-content .gallery-flexbox.row-3 .flexbox-service-wrapper:nth-child(3) {
    display: none;
  }
  section.domki-section-sea {
    padding-bottom: 0;
  }
  section.domki-section-sea .sea-background {
    width: 100%;
    height: 150px;
    background: #14215e;
  }
  section.domki-section-sea .sea-slogan {
    display: none;
  }
  section.domki-section-sea .sea-flexbox {
    flex-direction: column;
  }
  section.domki-section-sea .sea-flexbox .flexbox-image {
    width: 100%;
  }
  section.domki-section-sea .sea-flexbox .flexbox-image .localisation-map {
    width: 100%;
    height: 270px;
  }
  section.domki-section-sea .sea-flexbox .flexbox-description {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
  section.domki-section-sea .sea-flexbox .flexbox-description h2 br {
    content: '';
  }
  section.domki-section-sea .sea-flexbox .flexbox-description h2 br:after {
    content: ' ';
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses {
    width: 20%;
    min-width: 81px;
    padding-top: 100px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service {
    padding: 0 0 0 10px;
    height: 40px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service img {
    display: block;
    width: 18px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service p {
    font-size: 16px;
    margin-left: 8px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service p span {
    display: none;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column {
    min-width: 40px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell {
    height: 40px;
    padding: 6px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .free {
    width: 100%;
    height: 100%;
    background: #4e950f;
    border-radius: 3px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-family: 'Frank Ruhl Libre', serif;
    text-transform: uppercase;
    color: #aaa;
    background: #ddd;
    border-radius: 3px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved:after {
    content: 'r';
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell:not(:nth-child(2)) {
    border-top: none;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.saturday {
    background: #f9f9f9;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.sunday {
    background: #f3f3f3;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-heading,
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-cell {
    border-right: none;
  }
  section.opinie-section-content {
    padding-top: 0;
  }
  section.opinie-section-content .content-flexbox {
    flex-direction: column-reverse;
  }
  section.opinie-section-content .content-flexbox .flexbox-description {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  section.opinie-section-content .content-flexbox .flexbox-service {
    width: 100%;
    text-align: center;
    background: #14215e;
    padding: 30px;
  }
  section.opinie-section-content .content-flexbox .flexbox-service h3 {
    font-family: 'Frank Ruhl Libre', serif;
    font-size: 25px;
    color: #fff;
    font-weight: 900;
  }
  section.opinie-section-content .content-flexbox .flexbox-service p {
    margin-top: 5px;
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    padding-left: 4px;
    color: #fff;
  }
  section.opinie-section-content .content-flexbox:nth-child(even) {
    flex-direction: column-reverse;
  }
  section.section-footer .footer-credits {
    text-align: center;
    justify-content: center;
  }
  section.section-footer .alert {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  section.section-subpage-heading .heading-content {
    margin: 0;
  }
  nav.section-header .header-flexbox {
    padding: 40px 0;
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 40px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
    margin-right: 0;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses {
    width: 20%;
    min-width: 81px;
    padding-top: 100px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service {
    padding: 0 0 0 10px;
    height: 40px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service img {
    display: block;
    width: 18px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service p {
    font-size: 16px;
    margin-left: 8px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-houses .house-service p span {
    display: none;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column {
    min-width: 40px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell {
    height: 40px;
    padding: 6px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .free {
    width: 100%;
    height: 100%;
    background: #4e950f;
    border-radius: 3px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-family: 'Frank Ruhl Libre', serif;
    text-transform: uppercase;
    color: #aaa;
    background: #ddd;
    border-radius: 3px;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell .reserved:after {
    content: 'r';
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column .column-cell:not(:nth-child(2)) {
    border-top: none;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.saturday {
    background: #f9f9f9;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column.sunday {
    background: #f3f3f3;
  }
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-heading,
  section.rezerwacje-section-content .content-calendar .calendar-dates .dates-flexbox .date-column:last-child .column-cell {
    border-right: none;
  }
  section.kontakt-section-content .content-flexbox .flexbox-description address a {
    font-size: 20px;
  }
  section.static-page-section-content .content-pricing .pricing-heading {
    font-size: 20px;
  }
  section.static-page-section-content .content-pricing .pricing-heading small {
    white-space: nowrap;
  }
  section.static-page-section-content .content-pricing .pricing-list {
    font-size: 11px;
  }
  section.static-page-section-content .content-pricing .pricing-list .list-date {
    width: auto;
    padding: 10px 20px;
  }
  section.static-page-section-content .content-pricing .pricing-list .list-price {
    padding: 10px 20px;
  }
  section.static-page-section-content .content-pricing:not(:first-child) {
    margin-top: 50px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 50px;
  }
  section.section-footer .footer-flexbox .flexbox-service .list-social {
    justify-content: center;
  }
}
@media (max-width: 400px) {
  section.domki-section-welcome .welcome-content .content-flexbox .flexbox-description table {
    transform: translateX(0);
  }
}
