@import url("https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:400,500,700,900&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap&subset=latin-ext");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .btn {
    padding: 15px 35px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    white-space: normal;

    img {
      margin-left: 15px;
      height: 10px;
    }

    &.btn-default {
      background: #333;
      color: #fff;
      border: 1px solid #333;

      &:hover {
        background: darken(#333, 10%);
        border-color: darken(#333, 10%);
      }
    }

    &.btn-blue {
      background: @color1;
      color: #fff;
      border: 1px solid @color1;

      &:hover {
        background: darken(@color1, 10%);
        border-color: darken(@color1, 10%);
      }
    }

    &.btn-small {
      padding: 8px 20px 7px 20px;
      font-size: 11px;
    }
  }
}

@color1: #14215e;

.system-alerts {
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    opacity: 0.9;
    transition: all 0.4s;

    .alert-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-hidden {
        visibility: hidden;
        opacity: 0;
        height: 18px;
      }

      p {
        color: #fff;
        padding: 0 50px;
      }

      button {
        color: #fff;
        opacity: 1;
        font-weight: 300;
        float: none;

        img {
          height: 18px;
        }
      }
    }

    &.alert-success {
      background: #76ad21;
    }

    &.alert-info {
      background: #218ead;
    }

    &.alert-warning {
      background: #c52b20;
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

section.section-header-wrapper {
  position: relative;
  padding-bottom: 100px;

  &.subpage {
    padding-bottom: 0;
  }

  .header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 37%;
    height: 100%;
    background: @color1;
  }

  .header-slogan {
    position: absolute;
    bottom: 0;
    left: 50px;
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    transform-origin: bottom left;

    span {
      display: block;
      width: 300px;
      height: 1px;
      background: #fff;
      margin-right: 20px;
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

nav.section-header {
  position: relative;

  .header-flexbox {
    position: relative;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-logo {
      img {
        height: 50px;
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 60px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          margin-right: -10px;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #fff;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            margin-right: 40px;

            a {
              color: #000;
              transition: all 0.3s;

              img {
                height: 20px;
              }

              &.active {
                color: @color1;
                font-weight: 900;
              }
            }

            &:last-child {
              margin-right: 0;
            }

            &:nth-child(7) {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;
      cursor: pointer;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }

      &.hidden {
        .button-bar {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.section-header-subpage {
  position: relative;

  .header-subpage-flexbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .flexbox-description {
      text-align: right;

      h1 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 25px;
        color: #333;
        font-weight: 900;
      }

      a {
        margin-top: 25px;
        font-family: "Frank Ruhl Libre", serif;
        font-size: 40px;
        font-weight: 900;
        color: @color1;
      }
    }
  }
}

section.section-welcome {
  position: relative;

  .welcome-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-image {
      width: 50%;

      img {
        width: 100%;
      }
    }

    .flexbox-description {
      width: 43%;

      span {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 30px;
        color: @color1;
        font-weight: 900;
      }

      h1 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 55px;
        color: #333;
        font-weight: 900;
      }

      p {
        margin-top: 25px;
        font-size: 15px;
        line-height: 26px;
        padding-left: 4px;
        width: 80%;
        color: #666;

        a {
          font-weight: 900;
          color: @color1;
          text-decoration: underline !important;
        }

        strong {
          font-weight: 900;
          color: @color1;
        }

        &:first-of-type {
          margin-top: 0;
          width: 100%;
          padding-left: 0;
          font-family: "Frank Ruhl Libre", serif;
          font-size: 30px;
          color: @color1;
          font-weight: 900;
        }
      }

      .btn {
        margin-top: 25px;
      }
    }
  }
}

section.section-info {
  padding: 70px 0;

  .info-content {
    text-align: center;

    img {
      width: 50px;
      margin-bottom: 20px;
    }

    p {
      font-family: "Frank Ruhl Libre", serif;
      font-size: 40px;
      color: #333;
      font-weight: 900;

      a {
        color: @color1;
        white-space: nowrap;
      }
    }
  }
}

section.section-about {
  padding: 100px 0;
  position: relative;

  .about-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background: @color1;
  }

  .about-slogan {
    position: absolute;
    bottom: 0;
    right: 50px;
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    transform-origin: bottom right;

    span {
      display: block;
      width: 250px;
      height: 1px;
      background: #fff;
      margin-left: 20px;
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
      color: #fff;
    }
  }

  .about-flexbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-image {
      width: 50%;

      .localisation-map {
        width: 100%;
        height: 400px;
      }
    }

    .flexbox-description {
      width: 43%;

      h1 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 55px;
        color: #333;
        font-weight: 900;
      }

      p {
        margin-top: 25px;
        font-size: 15px;
        line-height: 26px;
        padding-left: 4px;
        width: 80%;
        color: #666;

        a {
          font-weight: 900;
          color: @color1;
          text-decoration: underline !important;
        }
      }

      .btn {
        margin-top: 25px;
      }
    }
  }
}

section.section-advantages {
  padding-bottom: 100px;

  .advantages-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      width: 30%;

      .service-image {
        width: 100%;
        padding-top: 100%;
      }

      .service-description {
        margin-top: -40px;
        text-align: center;

        h2 {
          font-size: 17px;
          font-weight: 700;
          color: #333;
          text-transform: uppercase;

          &:before {
            content: "";
            display: block;
            margin: 0 auto 15px auto;
            height: 80px;
            width: 3px;
            background: #333;
          }
        }

        p {
          margin-top: 10px;
          font-size: 15px;
          line-height: 26px;
          color: #666;
        }

        .btn {
          margin-top: 20px;
        }
      }

      &:nth-child(2) {
        margin-top: 100px;
      }

      &:nth-child(3) {
        margin-top: 50px;
      }
    }
  }
}

section.section-subpage-heading {
  .heading-border {
    margin-top: 35px;
    padding-top: 50px;
    height: 1px;
    width: 100%;
    border-top: 1px solid #efefef;
  }

  .heading-content {
    margin: 0 20%;
    text-align: center;

    h1,
    h2 {
      font-family: "Frank Ruhl Libre", serif;
      font-size: 55px;
      color: #333;
      font-weight: 900;

      a {
        color: @color1;
      }
    }

    p {
      margin-top: 15px;
      font-size: 15px;
      line-height: 26px;
      color: #666;

      a {
        font-weight: 900;
        color: @color1;
        text-decoration: underline !important;
      }
    }
  }
}

section.atrakcje-section-content {
  padding-top: 70px;
  padding-bottom: 10px;

  .advantages-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-service {
      width: 30%;

      .service-image {
        width: 100%;
        padding-top: 100%;
      }

      .service-description {
        margin-top: -40px;
        text-align: center;

        h2 {
          font-size: 17px;
          font-weight: 700;
          color: #333;
          text-transform: uppercase;

          &:before {
            content: "";
            display: block;
            margin: 0 auto 15px auto;
            height: 80px;
            width: 3px;
            background: #333;
          }
        }
      }
    }

    .flexbox-description {
      width: 65%;
      padding-right: 20%;
      transform: translateY(-35px);

      p {
        font-size: 15px;
        line-height: 26px;
        color: #666;

        strong {
          font-weight: 900;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .flexbox-service {
        display: flex;
        flex-direction: column-reverse;

        .service-image {
          margin-top: -40px;
        }

        .service-description {
          margin-top: 0;
          text-align: center;

          h2 {
            display: flex;
            flex-direction: column-reverse;

            &:before {
              margin: 15px auto 0 auto;
            }
          }
        }
      }

      .flexbox-description {
        padding-right: 0;
        padding-left: 20%;
        transform: translateY(35px);
      }
    }
  }
}

section.domki-section-welcome {
  position: relative;
  margin-top: 60px;
  padding: 50px 0;

  .welcome-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background: @color1;
  }

  .welcome-content {
    position: relative;

    .content-flexbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flexbox-description {
        width: 47%;

        h2 {
          font-family: "Frank Ruhl Libre", serif;
          font-size: 40px;
          color: #333;
          font-weight: 900;
        }

        p {
          margin-top: 25px;
          font-size: 15px;
          line-height: 26px;
          padding-left: 4px;
          width: 90%;
          color: #666;

          a {
            font-weight: 900;
            color: @color1;
            text-decoration: underline !important;
          }

          strong {
            font-weight: 900;
          }
        }

        ul {
          padding-left: 5px;
          margin-top: 20px;
          list-style: none;

          li {
            font-size: 15px;
            line-height: 26px;
            color: #666;
            display: flex;

            &:before {
              content: "\2022";
              color: @color1;
              margin-right: 5px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }

        .btn {
          margin-top: 25px;
        }
      }

      .flexbox-image {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }
  }
}

section.domki-section-content {
  padding-bottom: 70px;

  .content-about {
    background: #efefef;
    padding: 50px 0;
    margin-bottom: 70px;

    .about-flexbox {
      display: flex;
      flex-wrap: wrap;

      .flexbox-service {
        width: calc(100% / 5);
        padding: 20px;

        h2 {
          font-family: "Frank Ruhl Libre", serif;
          font-size: 20px;
          color: #333;
          font-weight: 900;
        }

        ul {
          margin-top: 10px;
          list-style: none;

          li {
            font-size: 13px;
            line-height: 26px;
            color: #666;
            display: flex;

            &:before {
              content: "\2022";
              color: @color1;
              margin-right: 5px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .gallery-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;

    li {
      margin-right: 5px;

      a,
      button {
        background: none;
        border: none;
        padding: 15px;
        border: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.5px;
        transition: all 0.3s;
        white-space: normal;
        cursor: pointer;
        color: #333;

        &:hover {
          background: @color1;
          color: #fff;
        }
      }

      &.active {
        a,
        button {
          background: @color1;
          color: #fff;
        }
      }
    }
  }

  .gallery-flexbox {
    margin-top: -3.5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service-wrapper {
      margin-top: 3.5%;
      width: 31%;
      position: relative;
      overflow: hidden;

      .flexbox-service {
        width: 100%;
        padding-top: 53%;
        display: block;

        .service-inner {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            transition: all 0.3s;
          }
        }

        iframe,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
        }

        &:hover {
          .service-inner {
            img {
              transform: scale(1.06);
            }
          }
        }
      }

      &.service-hidden {
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        border: none;
      }
    }
  }

  &.galeria {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

section.domki-section-sea {
  padding: 100px 0;
  margin-bottom: 10px;
  position: relative;

  .sea-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    height: 100%;
    background: @color1;
  }

  .sea-slogan {
    position: absolute;
    bottom: 0;
    left: 50px;
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    transform-origin: bottom left;

    span {
      display: block;
      width: 300px;
      height: 1px;
      background: #fff;
      margin-right: 20px;
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
      color: #fff;
    }
  }

  .sea-flexbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-image {
      width: 50%;

      .localisation-map {
        width: 100%;
        height: 400px;
      }
    }

    .flexbox-description {
      width: 43%;

      h2 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 40px;
        color: #333;
        font-weight: 900;
      }

      p {
        margin-top: 25px;
        font-size: 15px;
        line-height: 26px;
        padding-left: 4px;
        width: 80%;
        color: #666;

        a {
          font-weight: 900;
          color: @color1;
          text-decoration: underline !important;
        }

        strong {
          font-weight: 900;
        }
      }

      .btn {
        margin-top: 25px;
      }
    }
  }
}

section.rezerwacje-section-content {
  .content-legend {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;

    .legend-contact {
      display: flex;
      align-items: center;
      margin-left: -10px;

      .btn {
        margin: 10px;
      }
    }

    .legend-inner {
      margin-right: -10px;
      display: flex;
      align-items: center;

      .legend-service {
        display: flex;
        align-items: center;
        margin: 10px;

        .service-square {
          height: 28px;
          min-width: 28px;
          max-width: 28px;
          border-radius: 3px;
          margin-right: 10px;

          &.free {
            background: #4e950f;
          }

          &.reserved {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            font-family: "Frank Ruhl Libre", serif;
            text-transform: uppercase;
            color: #aaa;
            background: #ddd;
            position: relative;
            overflow: hidden;
            border-radius: 3px;

            .reserved-half-from {
              position: absolute;
              background: #4e950f;
              height: 100%;
              width: 100%;
              top: 0;
              right: 0;
              clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            }

            .reserved-half-to {
              position: absolute;
              background: #4e950f;
              height: 100%;
              width: 100%;
              top: 0;
              right: 0;
              clip-path: polygon(100% 0, 0% 100%, 0 0);
            }

            &:after {
              content: "r";
            }
          }
        }

        p {
          font-size: 12px;
          color: @color1;
        }
      }
    }
  }

  .content-calendar-navigation {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background: @color1;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      padding: 5px 15px;

      img {
        height: 20px;
      }

      &:hover {
        background: darken(@color1, 10%);
      }

      &:last-child {
        margin-left: 5px;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .content-calendar {
    display: flex;

    .calendar-houses {
      width: 20%;
      min-width: 160px;
      padding-top: 100px;

      .house-service {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 0 20px;
        background: none;
        border: 0.5px solid #efefef;
        border-right: none;
        cursor: pointer;
        outline: none;
        transition: all 0.3s;

        img {
          display: none;
        }

        p {
          font-family: "Frank Ruhl Libre", serif;
          font-size: 20px;
          color: #333;
          font-weight: 900;
          transition: all 0.3s;
        }

        &:hover {
          background: #d9dbe7;
          background: @color1;

          p {
            color: #fff;
          }
        }

        &:not(:first-child) {
          border-top: none;
        }
      }
    }

    .calendar-dates {
      width: 80%;
      border-left: 0.5px solid #ddd;
      border-right: 0.5px solid #ddd;

      .dates-flexbox {
        display: flex;

        .date-column {
          min-width: 60px;

          .column-heading {
            height: 100px;
            border: 0.5px solid #ddd;
            border-left: none;
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            .year {
              font-size: 10px;
              font-weight: 400;
              color: #888;
            }

            .name {
              margin-top: 5px;
              font-size: 12px;
              font-weight: 300;
              color: #333;
            }

            .name {
              font-size: 12px;
              font-weight: 300;
              color: #333;
            }

            .day {
              margin-top: 5px;
              font-weight: 900;
              font-family: "Frank Ruhl Libre", serif;
              color: @color1;
              line-height: 22px;
              font-size: 22px;
            }

            .month {
              font-size: 12px;
              font-weight: 600;
              color: @color1;
            }
          }

          .column-cell {
            border: 0.5px solid #ddd;
            border-left: none;
            height: 60px;
            padding: 13px;

            .free {
              width: 100%;
              height: 100%;
              background: #4e950f;
              border-radius: 3px;
            }

            .reserved {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 900;
              font-family: "Frank Ruhl Libre", serif;
              text-transform: uppercase;
              color: #aaa;
              background: #ddd;
              border-radius: 3px;
              position: relative;
              overflow: hidden;

              .reserved-half-from {
                position: absolute;
                background: #4e950f;
                height: 100%;
                width: 100%;
                top: 0;
                right: 0;
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
              }

              .reserved-half-to {
                position: absolute;
                background: #4e950f;
                height: 100%;
                width: 100%;
                top: 0;
                right: 0;
                clip-path: polygon(100% 0, 0% 100%, 0 0);
              }

              &:after {
                content: "r";
              }
            }

            &:not(:nth-child(2)) {
              border-top: none;
            }
          }

          &.saturday {
            background: #f9f9f9;
          }

          &.sunday {
            background: #f3f3f3;
          }

          &:last-child {
            .column-heading,
            .column-cell {
              border-right: none;
            }
          }
        }
      }
    }
  }
}

.modal-content {
  border: none;
  border-radius: 0;

  .modal-header {
    border-bottom: none;
  }

  .modal-title {
    font-family: "Frank Ruhl Libre", serif;
    font-size: 28px;
    color: #333;
    font-weight: 900;
  }

  .modal-photo {
    img {
      width: 100%;
    }
  }

  .modal-pricing {
    padding: 1rem;

    .heading {
      font-family: "Frank Ruhl Libre", serif;
      font-size: 22px;
      color: #333;
      font-weight: 900;
      margin-bottom: 10px;
    }

    .pricing-row {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 15px;
        line-height: 26px;
        color: #666;

        b {
          font-weight: 900;
        }
      }

      &:not(:last-child) {
        border-bottom: 0.5px solid #efefef;
      }
    }
  }

  .close {
    outline: none;
    border: none;
    background: none;
    padding: 15px;
    display: flex;
    transform: translate(-18px, 22px);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    .button-bar {
      display: block;
      position: absolute;
      width: 25px;
      height: 2px;
      background: #333;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
}

section.opinie-section-content {
  padding-top: 50px;

  .content-flexbox {
    border-top: 1px solid #efefef;
    padding-top: 50px;
    margin: 0 10%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-description {
      width: 65%;

      p {
        font-size: 17px;
        line-height: 26px;
        color: #666;
        font-style: italic;

        &:before,
        &:after {
          content: '"';
        }
      }
    }

    .flexbox-service {
      width: 30%;
      background: @color1;
      padding: 30px;

      h3 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 25px;
        color: #fff;
        font-weight: 900;
      }

      p {
        margin-top: 5px;
        font-weight: 300;
        font-size: 13px;
        line-height: 26px;
        padding-left: 4px;
        color: #fff;
      }
    }

    &:nth-child(1) {
      padding-top: 0;
      border: none;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

section.kontakt-section-content {
  margin-top: 70px;
  padding: 50px 0;
  position: relative;

  .content-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background: @color1;
  }

  .content-flexbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-image {
      width: 50%;

      .localisation-map {
        width: 100%;
        height: 400px;
      }
    }

    .flexbox-description {
      width: 43%;

      h2 {
        font-family: "Frank Ruhl Libre", serif;
        font-size: 40px;
        color: #333;
        font-weight: 900;
      }

      address {
        margin-top: 25px;
        font-size: 28px;
        line-height: 26px;
        padding-left: 4px;
        width: 80%;
        color: #666;

        a {
          font-family: "Frank Ruhl Libre", serif;
          font-weight: 900;
          color: @color1;

          &:nth-child(2) {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }

      .btn {
        margin-top: 25px;
      }
    }
  }
}

section.kontakt-section-info {
  padding: 70px 0 60px 0;

  .info-content {
    text-align: center;

    p {
      font-family: "Frank Ruhl Libre", serif;
      font-size: 40px;
      color: #333;
      font-weight: 900;

      a {
        color: @color1;
      }
    }

    img {
      height: 30px;
    }
  }
}

section.static-page-section-content {
  padding: 50px 0;

  .content-text {
    > * {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      font-size: 15px;
      line-height: 26px;
      color: #666;
    }

    ul,
    ol {
      padding-left: 18px;
      margin-bottom: 0;

      li {
        font-size: 15px;
        line-height: 26px;
        color: #666;
      }

      ul,
      ol {
        margin-top: 0;
        list-style-type: lower-alpha;

        li {
          opacity: 1;
        }
      }
    }
  }

  .content-pricing {
    .pricing-heading {
      font-size: 25px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }

    .pricing-list {
      margin-top: 5px;
      display: flex;
      justify-content: center;

      .list-date {
        width: 300px;
        text-align: center;
        padding: 10px;
        background: #f9f9f9;
      }

      .list-price {
        background: @color1;
        color: #fff;
        padding: 10px 30px;
        font-weight: 300;

        strong {
          font-weight: 800;
        }
      }
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
  }
}

section.section-footer {
  padding: 20px 0 50px 0;
  background: darken(@color1, 10%);
  position: relative;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 50px;

      .service-logo {
        height: 50px;
      }

      ul {
        li {
          margin-top: 10px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: #e6e6e5;
          }

          a {
            font-size: 12px;
            color: #e6e6e5;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }
          }

          p {
            font-size: 12px;
            color: #e6e6e5;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .footer-credits {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
      font-size: 12px;
      color: #e6e6e5;
      font-weight: 300;

      a {
        color: #e6e6e5;
      }
    }
  }

  .alert {
    position: relative;
    margin-top: 50px;
    margin-bottom: 0;
    padding: 50px 0 0 0;
    background: none;
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    p {
      font-size: 12px;
      color: #e6e6e5;
      font-weight: 300;

      a {
        color: #e6e6e5;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
}
